import React from 'react';
import type { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby';
import { UserData } from '@src/user-data';
import { UserDataConfig } from '@src/config';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = (args: WrapRootElementBrowserArgs) => {
  const { element } = args;

  return <UserData options={UserDataConfig}>{element}</UserData>;
}
