import { createContext } from 'react';
import { LeadModel } from '@model';
import { UserDataContextModel } from './interfaces';

export const UserDataContext = createContext<UserDataContextModel>({
  values: {
    defendants: [],
    groups: []
  },
  errors: {},
  updateData: (anything: Partial<LeadModel>) => anything && null,
  clear: () => null,
});
