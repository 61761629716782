/* eslint-disable */
import Filter from 'bad-words';
import { AddressValidationModel, ValidationModel } from '@model';
import { emailAdvancedValidation } from './email-validation-api';

const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
const ukMobilePhoneNumberOnlyRegex: RegExp = /^(((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6})$/;
const nationalInsuranceNumberRegex: RegExp = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/i;
const postcodeRegex: RegExp =
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z]\d{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y]\d{1,2})|(([A-Za-z]\d[A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y]\d[A-Za-z]?))))\s?\d[A-Za-z]{2})$/;
const payeReferenceNumberRegex: RegExp = /^\d{3}[A-Z]{1,2}\d+$/;

const badWords = [
  '*damn',
  '*dyke',
  '*fuck*',
  '*shit*',
  '2 girls 1 cup',
  '2g1c',
  '@$$',
  'acrotomophilia',
  'ahole',
  'alabama hot pocket',
  'alaskan pipeline',
  'amcik',
  'anal',
  'andskota',
  'anilingus',
  'anus',
  'apeshit',
  'arschloch',
  'arse',
  'arse*',
  'arsehle',
  'arsehole',
  'asdas',
  'asdasd',
  'asdf',
  'asdfd',
  'ash0le',
  'ash0les',
  'asholes',
  'ass',
  'ass lick',
  'ass monkey',
  'asses',
  'assface',
  'assh0le',
  'assh0lez',
  'asshole',
  'assholes',
  'assholz',
  'asskisser',
  'assmunch',
  'assrammer',
  'asswipe',
  'auto erotic',
  'autoerotic',
  'ayir',
  'azzhole',
  'b!+ch',
  'b!tch',
  'b00b*',
  'b00bs',
  'b17ch',
  'b1tch',
  'babble',
  'babeland',
  'baby batter',
  'baby juice',
  'ball gag',
  'ball gravy',
  'ball kicking',
  'ball licking',
  'ball sack',
  'ball sucking',
  'bangbros',
  'bangbus',
  'bareback',
  'barely legal',
  'barenaked',
  'bassterds',
  'bastard',
  'bastardo',
  'bastards',
  'bastardz',
  'basterds',
  'basterdz',
  'bastinado',
  'bbbb',
  'bbw',
  'bdsm',
  'beaner',
  'beaners',
  'beastial',
  'beastiality',
  'beastility',
  'beaver cleaver',
  'beaver lips',
  'belly whacker',
  'bestial',
  'bestiality',
  'bi+ch',
  'bi7ch',
  'biatch',
  'big black',
  'big breasts',
  'big knockers',
  'big tits',
  'bigdick',
  'bimbos',
  'birdlock',
  'bitch',
  'bitch*',
  'bitcher',
  'bitchers',
  'bitches',
  'bitchin',
  'bitching',
  'black cock',
  'blob',
  'blonde action',
  'blonde on blonde action',
  'blow job',
  'blow your load',
  'blowjob',
  'blowjobs',
  'blue waffle',
  'blumpkin',
  'boffing',
  'boiolas',
  'bollock*',
  'bollocks',
  'bondage',
  'boner',
  'boob',
  'boobs',
  'booty call',
  'breasts',
  'brown eye',
  'brown showers',
  'browneye',
  'browntown',
  'brunette action',
  'buceta',
  'bucket cunt',
  'bukkake',
  'bull shit',
  'bulldyke',
  'bullet vibe',
  'bullshit',
  'bung hole',
  'bunghole',
  'busty',
  'butch',
  'butt',
  'butt breath',
  'butt fucker',
  'butt hair',
  'butt-pirate',
  'buttcheeks',
  'buttface',
  'buttfuck',
  'buttfucker',
  'butthead',
  'butthole',
  'buttpicker',
  'buttwipe',
  'c0ck',
  'c0cks',
  'c0k',
  'cabron',
  'camel toe',
  'camgirl',
  'camslut',
  'camwhore',
  'carpet muncher',
  'carpetmuncher',
  'cawk',
  'cawks',
  'cazzo',
  'chink',
  'chocolate rosebuds',
  'chraa',
  'chuj',
  'cialis',
  'cipa',
  'circle jerk',
  'circlejerk',
  'cleveland steamer',
  'clit',
  'clitoris',
  'clits',
  'clover clamps',
  'clusterfuck',
  'cnts',
  'cntz',
  'cobia',
  'cock',
  'cock*',
  'cock-head',
  'cock-sucker',
  'cockhead',
  'cockknocker',
  'cocks',
  'cocksuck',
  'cocksucked',
  'cocksucker',
  'cocksucking',
  'cocksucks',
  'cockwomble',
  'coon',
  'coons',
  'coprolagnia',
  'coprophilia',
  'cornhole',
  'cow',
  'crap',
  'creampie',
  'cum',
  'cummer',
  'cumming',
  'cumshot',
  'cumshots',
  'cunilingus',
  'cunillingus',
  'cunnilingus',
  'cunt',
  'cunt*',
  'cuntlick',
  'cuntlicker',
  'cuntlicking',
  'cunts',
  'cuntz',
  'cyberfuc',
  'cyberfuck',
  'cyberfucked',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking',
  'd4mn',
  'darkie',
  'date rape',
  'daterape',
  'daygo',
  'deep throat',
  'deepthroat',
  'dego',
  'dendrophilia',
  'dfjj',
  'dick',
  'dick*',
  'dickbender',
  'dickhead',
  'dicklicker',
  'dickwart',
  'dike',
  'dike*',
  'dild0',
  'dild0s',
  'dildo',
  'dildos',
  'dilld0',
  'dilld0s',
  'dingleberries',
  'dingleberry',
  'dipshit',
  'dirsa',
  'dirty pillows',
  'dirty sanchez',
  'dlk',
  'dog style',
  'doggie style',
  'doggiestyle',
  'doggy style',
  'doggystyle',
  'dogsbollocks',
  'dolcett',
  'domination',
  'dominatricks',
  'dominatrics',
  'dominatrix',
  'dommes',
  'donkey punch',
  'double dong',
  'double penetration',
  'douche bag',
  'dp action',
  'drtgsd',
  'dry hump',
  'dsd',
  'dsfasdf',
  'dumbass',
  'dupa',
  'dvda',
  'dyke',
  'dziwka',
  'eat my ass',
  'ecchi',
  'ejackulate',
  'ejaculate',
  'ejaculated',
  'ejaculates',
  'ejaculating',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'ekrem*',
  'ekto',
  'enculer',
  'enema',
  'eraareeg',
  'erotic',
  'erotism',
  'escort',
  'eunuch',
  'f u c k',
  'f u c k e r',
  'faen',
  'fag',
  'fag*',
  'fag1t',
  'faget',
  'fagg0t',
  'fagg1t',
  'faggit',
  'faggot',
  'fagit',
  'fags',
  'fagz',
  'faig',
  'faigs',
  'fanculo',
  'fanny',
  'fart',
  'farted',
  'farting',
  'fartings',
  'farts',
  'farty',
  'fatass',
  'fatso',
  'fatty',
  'fcuk',
  'fecal',
  'feces',
  'feg',
  'felatio',
  'felch',
  'felcher',
  'fellatio',
  'feltch',
  'female squirting',
  'femdom',
  'fgff',
  'fhgoh',
  'ficken',
  'figging',
  'fingerbang',
  'fingerfuck',
  'fingerfucked',
  'fingerfucker',
  'fingerfuckers',
  'fingerfucking',
  'fingerfucks',
  'fingering',
  'fistfuck',
  'fistfucked',
  'fistfucker',
  'fistfuckers',
  'fistfucking',
  'fistfuckings',
  'fistfucks',
  'fisting',
  'fitt*',
  'flikker',
  'flipping the bird',
  'foot fetish',
  'footjob',
  'foreskin',
  'fotze',
  'frotting',
  'fu(*',
  'fuck',
  'fuck buttons',
  'fucked',
  'fucker',
  'fuckers',
  'fuckin',
  'fucking',
  'fuckings',
  'fuckme',
  'fucks',
  'fucktards',
  'fudge packer',
  'fudgepacker',
  'fuk',
  'fuk*',
  'fukah',
  'fuken',
  'fuker',
  'fukin',
  'fukk',
  'fukkah',
  'fukken',
  'fukker',
  'fukkin',
  'furburger',
  'futanari',
  'futkretzn',
  'fux0r',
  'fwgag',
  'g-spot',
  'g00k',
  'gang bang',
  'gangbang',
  'gangbanged',
  'gangbangs',
  'gay sex',
  'gaysex',
  'gazongers',
  'geezer',
  'genitals',
  'gewqrf',
  'ghjkg',
  'ghsdf',
  'giant cock',
  'girl on',
  'girl on top',
  'girls gone wild',
  'goatcx',
  'goatse',
  'gobshit',
  'gobshite',
  'god damn',
  'god-damned',
  'goddamn',
  'gokkun',
  'golden shower',
  'gonads',
  'goo girl',
  'goodpoop',
  'gook',
  'goregasm',
  'grope',
  'group sex',
  'guiena',
  'guro',
  'h00r',
  'h0ar',
  'h0r',
  'h0re',
  'h4x0r',
  'hand job',
  'handjob',
  'hard core',
  'hard on',
  'hardcore',
  'hardcoresex',
  'hell',
  'hells',
  'helvete',
  'hentai',
  'hjhjgff',
  'hoar',
  'hoer',
  'hoer*',
  'homo',
  'homoerotic',
  'honkey',
  'hooker',
  'hoor',
  'hoore',
  'hore',
  'horniest',
  'horny',
  'hot carl',
  'hot chick',
  'hotsex',
  'how to kill',
  'how to murder',
  'huevon',
  'huge fat',
  'hugearse',
  'hughjarse',
  'hui',
  'humping',
  'incest',
  'infected',
  'infection',
  'injun',
  'intercourse',
  'interested',
  'jack off',
  'jack-off',
  'jacking off',
  'jackoff',
  'jail bait',
  'jailbait',
  'jailbird',
  'jap',
  'japs',
  'jelly donut',
  'jerk',
  'jerk off',
  'jerk-off',
  'jflkd',
  'jgy',
  'jigaboo',
  'jiggaboo',
  'jiggerboo',
  'jisim',
  'jism',
  'jiss',
  'jiz',
  'jizm',
  'jizz',
  'juggs',
  'kanker*',
  'kawk',
  'kfhgjkh',
  'kike',
  'kinbaku',
  'kinkster',
  'kinky',
  'kjk',
  'klootzak',
  'knob',
  'knobbing',
  'knobs',
  'knobz',
  'knulle',
  'kraut',
  'kuk',
  'kuksuger',
  'kunilingus',
  'kunt',
  'kunts',
  'kuntz',
  'kurac',
  'kurwa',
  'kusi*',
  'kyrpa*',
  'l3i+ch',
  'l3itch',
  'leather restraint',
  'leather straight jacket',
  'lemon party',
  'lesbian',
  'lesbo',
  'lezzian',
  'lgmgm',
  'lipshits',
  'lipshitz',
  'livesex',
  'lolita',
  'loser',
  'lovemaking',
  'make me come',
  'male squirting',
  'mamhoon',
  'masochist',
  'masokist',
  'massterbait',
  'masstrbait',
  'masstrbate',
  'masterbaiter',
  'masterbat*',
  'masterbat3',
  'masterbate',
  'masterbates',
  'masturbat*',
  'masturbate',
  'masturbating',
  'masturbation',
  'menage a trois',
  'merd*',
  'mibun',
  'milf',
  'missionary position',
  'mofo',
  'mong',
  'monkleigh',
  'motha fucker',
  'motha fuker',
  'motha fukkah',
  'motha fukker',
  'mothafuck',
  'mothafucka',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking',
  'mothafuckings',
  'mothafucks',
  'mother fucker',
  'mother fukah',
  'mother fuker',
  'mother fukkah',
  'mother fukker',
  'mother-fucker',
  'motherfuck',
  'motherfucked',
  'motherfucker',
  'motherfuckers',
  'motherfuckin',
  'motherfucking',
  'motherfuckings',
  'motherfucks',
  'mouliewop',
  'mound of venus',
  'mr hands',
  'muff diver',
  'muffdiving',
  'muie',
  'mulkku',
  'muschi',
  'mutha fucker',
  'mutha fukah',
  'mutha fuker',
  'mutha fukkah',
  'mutha fukker',
  'mvbm',
  'n1gr',
  'n\\/a',
  'nambla',
  'nastt',
  'nawashi',
  'nazi',
  'nazis',
  'negro',
  'neonazi',
  'nepesaurio',
  'nig nog',
  'nigga',
  'nigger',
  'nigger*',
  'nigger;',
  'niggers',
  'nigur;',
  'niiger;',
  'niigr;',
  'nimphomania',
  'nipple',
  'nipples',
  'nsfw',
  'nsfw images',
  'nude',
  'nudity',
  'nutsack',
  'nutten',
  'nympho',
  'nymphomania',
  'octopussy',
  'omorashi',
  'one cup two girls',
  'one guy one jar',
  'orafis',
  'orgasim',
  'orgasim;',
  'orgasims',
  'orgasm',
  'orgasms',
  'orgasum',
  'orgy',
  'oriface',
  'orifice',
  'orifiss',
  'orospu',
  'p0rn',
  'packi',
  'packie',
  'packy',
  'paedophile',
  'paki',
  'pakie',
  'paky',
  'panties',
  'panty',
  'paska*',
  'pecker',
  'pedobear',
  'pedophile',
  'peeenus',
  'peeenusss',
  'peenus',
  'pegging',
  'peinus',
  'pen1s',
  'penas',
  'penis',
  'penis-breath',
  'penus',
  'penuus',
  'perse',
  'phone sex',
  'phonesex',
  'phuc',
  'phuck',
  'phuk',
  'phuked',
  'phuker',
  'phukked',
  'phukker',
  'picka',
  'piece of shit',
  'pierdol*',
  'pikey',
  'pillu*',
  'pimmel',
  'pimp',
  'pimpis',
  'piss',
  'piss pig',
  'piss*',
  'pissed',
  'pissers',
  'pisses',
  'pissin',
  'pissing',
  'pissoff',
  'pisspig',
  'pissrr',
  'pizda',
  'playboy',
  'pleasure chest',
  'polac',
  'polack',
  'polak',
  'pole smoker',
  'ponyplay',
  'poo',
  'poof',
  'poon',
  'poonani',
  'poontang',
  'poontsee',
  'poop',
  'poop chute',
  'poopchute',
  'porn',
  'porno',
  'pornography',
  'pr0n',
  'pr1c',
  'pr1ck',
  'pr1k',
  'preteen',
  'prick',
  'pricks',
  'prince albert piercing',
  'pthc',
  'pubes',
  'pula',
  'pule',
  'punany',
  'pusse',
  'pussee',
  'pussies',
  'pussy',
  'pussys',
  'puta',
  'puto',
  'puuke',
  'puuker',
  'qahbeh',
  'queaf',
  'queef',
  'queef*',
  'queer',
  'quim',
  'qweir',
  'qwerty',
  'raghead',
  'raging boner',
  'rape',
  'raping',
  'rapist',
  'rautenberg',
  'recktum',
  'rectum',
  'retard',
  'reverse cowgirl',
  'rimjob',
  'rimming',
  'rosy palm',
  'rosy palm and her 5 sisters',
  'rtyrt',
  'rusty trombone',
  's&m',
  's.o.b.',
  'sadism',
  'sadist',
  'santorum',
  'scank',
  'scat',
  'scdscd',
  'schaffer',
  'scheiss*',
  'schlampe',
  'schlong',
  'schmuck',
  'scissoring',
  'screw',
  'screwing',
  'scrotum',
  'sddgff',
  'sdfg',
  'semen',
  'sex',
  'sexcam',
  'sexo',
  'sexual',
  'sexuality',
  'sexually',
  'sexy',
  'sh!+',
  'sh!t',
  'sh!t*',
  'sh1t',
  'sh1ter',
  'sh1ts',
  'sh1tter',
  'sh1tz',
  'sharmuta',
  'sharmute',
  'shaved beaver',
  'shaved pussy',
  'shemale',
  'shi+',
  'shibari',
  'shipal',
  'shit',
  'shitblimp',
  'shited',
  'shitehead',
  'shitfull',
  'shithead',
  'shiting',
  'shitings',
  'shits',
  'shitted',
  'shitter',
  'shitters',
  'shitting',
  'shittings',
  'shitty',
  'shity',
  'shitz',
  'shiz',
  'shota',
  'shrimping',
  'shyt',
  'shyte',
  'shytty',
  'shyty',
  'skanck',
  'skank',
  'skankee',
  'skankey',
  'skanks',
  'skanky',
  'skeet',
  'skribz',
  'skurwysyn',
  'slag',
  'slagface',
  'slanteye',
  'sleaze',
  'slut',
  'sluts',
  'slutty',
  'slutz',
  'smut',
  'snatch',
  'snowballing',
  'sodomize',
  'sodomy',
  'son-of-a-bitch',
  'spastic',
  'sphencter',
  'spic',
  'spierdalaj',
  'splooge',
  'splooge moose',
  'spooge',
  'spread legs',
  'spunk',
  'squelch',
  'strap on',
  'strapon',
  'strappado',
  'strip club',
  'style doggy',
  'suck',
  'sucks',
  'suicide girls',
  'suka',
  'sultry women',
  'surname',
  'swastika',
  'swinger',
  'tainted love',
  'taste my',
  'tea bagging',
  'teets',
  'teez',
  'tester',
  'testical',
  'testicle',
  'testicle*',
  'threesome',
  'throating',
  'thumbzilla',
  'tied up',
  'tight white',
  'tit',
  'tits',
  'titt',
  'titt*',
  'titties',
  'titty',
  'tongue in a',
  'topless',
  'tosser',
  'towelhead',
  'training',
  'tranny',
  'tribadism',
  'tub girl',
  'tubgirl',
  'turd',
  'tushy',
  'twat',
  'twink',
  'twinkie',
  'two girls one cup',
  'undressing',
  'unknown',
  'upskirt',
  'urethra play',
  'urine',
  'urophilia',
  'uutyty',
  'va1jina',
  'vag1na',
  'vagiina',
  'vagina',
  'vaj1na',
  'vajina',
  'venus mound',
  'viagra',
  'vibrator',
  'violet wand',
  'vittu',
  'vorarephilia',
  'voyeur',
  'voyeurweb',
  'voyuer',
  'vullva',
  'vulva',
  'w00se',
  'w0p',
  'wank',
  'wank*',
  'wanker',
  'wet dream',
  'wetback',
  'wetback*',
  'wgwg',
  'wh00r',
  'wh0re',
  'white power',
  'whoar',
  'whore',
  'wichser',
  'wop*',
  'worldsex',
  'wrapping men',
  'wrinkled starfish',
  'xrated',
  'xx',
  'xxx',
  'yaoi',
  'yed',
  'yellow showers',
  'yiffy',
  'ytyt',
  'zabourah',
  'zoophilia',
  '\ud83d\udd95',
];
const employerExclusionList = [
  'Am self Em',
  'As',
  'Asd',
  'ASGS',
  'Asw',
  'Bsh',
  'Bsmhft',
  'Carees allowance',
  'Carer allowance',
  'Carers allowance',
  'Carer`s Allowance',
  'carers allowence',
  'Ce',
  'Con',
  'Disability',
  'Disability Benefits',
  'Disabled',
  'disabylity',
  'Dol',
  'Dole',
  'Don’t have one',
  'Don’t have 1',
  "Don't have one",
  "Don't have 1",
  'Don`t have 1',
  'Don`t have one',
  'Dont have one',
  'Dont have 1',
  'Dunno',
  'fghfghg',
  'Fuckflaps',
  'Full employer',
  'full time',
  'Full time mam',
  'Full time mother',
  'Full time mum',
  'Fulltime',
  'Fulltime mum',
  'FUN',
  'Government allowance',
  'Government benefits',
  'Haven`t got one unemployed',
  'Holy',
  'Homemaker',
  'Homeowner',
  'House husband',
  'Housekeper',
  'Housewife',
  'House Wife',
  'Howskeeping',
  'Hshshs',
  'I am unemployed',
  'I don’t work',
  'I was working for supreme concrete got layed off',
  'Idk',
  'I`m unemployed',
  'It used to be mitie',
  'Job',
  'Job center',
  'Job centre',
  'Jobcentre',
  'Jobseeker',
  'Jobseeker’s',
  'Landscaping',
  'Mike',
  'Mother',
  'Mum',
  'Mum dry clean',
  'Myself',
  'N/a',
  'Na',
  'NHS retired 14=years ago',
  'No job',
  'No one',
  'No work',
  'Non',
  'None',
  'Not employed',
  'Not work',
  'Not working',
  'On pip now',
  'Out of work',
  'Pip',
  'Plevin',
  'Re',
  'Saint John of god',
  'Scammer',
  'Self empl',
  'Self employed',
  'Self employer',
  'self emplyed',
  'Self empolyed',
  'Selfemp',
  'Self-employed',
  'Selfemployer',
  'Tba',
  'TBC',
  'test company',
  'Test Employer',
  'Test Place',
  'tester',
  'Uhnm',
  'Un employed',
  'Unc',
  'Unemployed',
  'unemployer',
  'Unemployment',
  'Uninployed',
  'Univeral credit',
  'Universal credit',
  'universal credit benefits',
  'Universal credits',
  'Unployed',
  'Unversial credit',
  'various',
  'Work from home',
  'Self inployed',
  'Self  Employed',
  'Selff employees',
  'Self employment',
  'full tim',
  'Mother',
  'Self emp',
  'I’m now self employed but wasnt when covid here',
  'Do not have  one',
  'Self employed taix driver',
  'My hom',
  'Self em',
  'Agency work',
  'I get carers allowance',
  'diana pricess of wales',
  'Pension',
  'N/',
  'Self- Employed',
  'Myselg',
  'Me',
  'Self',
  'full tim',
  'Do not have one',
  'do not have 1',
  'as',
  'Re',
  'Con',
  "Carer's Allowance",
  'full time',
  'Un employed',
  'Univeral credit',
  'Na',
  "I'm unemployed",
  "Haven't got one unemployed",
  'Mum',
  'Job',
  '6444',
  'Self empl',
  'As',
  'Dol',
  'Re',
  'Ce',
  'Con',
  "Carer's Allowance",
  '2700',
  '2929.80',
  'Mother',
  'Self emp',
  'I’m now self employed but wasnt when covid here',
  'Do not have  one',
  'Self employed taix driver',
  '01412482724',
  'My hom',
  'Self em',
  'Agency work',
  '815445',
  'I get carers allowance',
  'diana pricess of wales',
  'Pension',
  'N/',
  'Self- Employed',
  'Myselg',
  'Me',
  'Self',
  'full tim',
  'Self employment',
  'Selff employees',
  'Self  Employed',
  'Self inployed',
  'Dont work',
  'Umployer',
  'Uneployed',
  'My new One is mearns house',
  'Myslef',
  'Restaurant',
  'I don’t have',
  'Worker',
  'Hgv driver',
  "Haven't got one",
  'the beatles',
  'Don’t work',
  'On benefits',
  'Cleaner',
  'No',
  'testing lead TSL',
  "I don't now",
  'I cant remember',
  '50015818',
  'Mother in law (home)',
  'i don’t',
  'Taxi driver',
  '20/06/2019',
  '05/05/2022',
  'Didn’t work from home then',
  'Al of 2021',
  'Don’t know',
  'Employer',
  'employed',
  'Your mums brothel',
  'anyone',
  'Delivery',
  '2021/2022',
  '2018/2019',
  'Area51',
  'Carer',
  'Cleaning',
  'Renata Abreu',
  '2020/2021',
  '£6500',
  'Selfemployed',
  'SX222624A',
  'Not applicable',
  '2sfg',
  'Yes',
  '2021&2022',
  'Employer',
  'yes',
  'My mom',
  'In the hotel. My payslip from 27 01 2021. 37, 5 houers',
  'Nobody',
  'Hosptail',
  'HM the queen',
  'Housekeeping',
  'Post man',
  'Mummy',
  'Company',
  'Nonexistent',
  'Waiter',
  'Șelf ployed',
  'My son',
  'No body',
  'Retired',
  'no',
  'N a',
  'Yes',
  'Employer',
  'Employer',
  'Employee',
  'Delivery driver',
  '2 yers',
  'No',
  '475/WE21524',
  '2020 to 2022',
  '2020 to 2021',
  '1257l',
  '2020-2021',
  'an employer i know',
];

const filter = new Filter({ list: badWords });
filter.removeWords('hoare', 'dick', 'cox', 'dyke');
const employerFilter = new Filter({ list: employerExclusionList });

/**
 * A collection of validation functions that return [ValidationModel]
 */
export abstract class Validators {
  /**
   * Combines a {ValidationModel[]} into the first invalid {ValidationModel}
   * or a single valid {ValidationModel}.
   * @param {ValidationModel[]} validationModels - The list to combine
   */
  public static combine(validationModels: ValidationModel[]): ValidationModel {
    for (let x = 0; x < validationModels.length; x += 1) {
      const validation = validationModels[x];
      if (!validation.valid) {
        return validation;
      }
    }

    return { valid: true };
  }

  public static address(addressValidationModel: AddressValidationModel): ValidationModel {
    const profanityValidation = this.combine(
      [addressValidationModel.line1, addressValidationModel.line2, addressValidationModel.town, addressValidationModel.postcode].map(
        (field) => this.profanity(field),
      ),
    );
    if (!profanityValidation.valid) {
      return profanityValidation;
    }

    const requiredValidation = this.combine(
      [addressValidationModel.line1, addressValidationModel.town, addressValidationModel.postcode].map((field) => this.required(field)),
    );
    if (!requiredValidation.valid) {
      return requiredValidation;
    }

    return { valid: true };
  }

  public static dob(dobString: string): ValidationModel {
    const userDob: Date = new Date(dobString);

    if (userDob.getDate().toString() !== 'NaN') {
      if (userDob <= new Date(new Date().setFullYear(new Date().getFullYear() - 18))) {
        return { valid: true };
      }

      return {
        valid: false,
        errorMessage: 'You need to be over 18 to pursue this claim',
      };
    }

    return { valid: false };
  }

  public static debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  public static async email(emailString: string): Promise<ValidationModel> {
    if (emailString && emailRegex.test(emailString)) {
      const res = await emailAdvancedValidation(emailString);

      if (res.success) {
        return { valid: true };
      }

      return {
        valid: false,
        errorMessage: 'Please provide a valid email address',
      };
    }
    return {
      valid: false,
      errorMessage: 'Please provide a valid email address',
    };
  }

  public static postcode(postcodeString: string): ValidationModel {
    if (postcodeString && postcodeRegex.test(postcodeString)) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errorMessage: 'Please enter a valid UK postcode',
    };
  }

  public static profanity(testString: string): ValidationModel {
    if (!filter.isProfane(testString)) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errorMessage: "This doesn't look right!",
    };
  }

  public static employerProfanity(testString: string): ValidationModel {
    if (!employerFilter.isProfane(testString)) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errorMessage: "This doesn't look right!",
    };
  }

  public static required(testString: string): ValidationModel {
    if (testString && testString.length > 0) {
      return { valid: true };
    }

    return {
      valid: false,
      errorMessage: 'This field is required',
    };
  }

  public static firstNameLastName(nameFromField: string, nameFromOtherField: string, partnerName?: string): ValidationModel {
    const valueSplit = Math.floor(nameFromField.length / 2);
    const firstHalf = nameFromField.substring(0, valueSplit).toLowerCase().trim();
    const secondHalf = nameFromField.substring(valueSplit, nameFromField.length).toLowerCase().trim();

    if (/[^A-Z- ]/gi.test(nameFromField)) {
      return {
        valid: false,
        errorMessage: 'Name cannot contain numbers or symbols',
      };
    }

    if (nameFromField && firstHalf === secondHalf) {
      return { valid: false, errorMessage: 'This doesn`t look right!' };
    }

    if (!nameFromField) {
      return { valid: false, errorMessage: 'This field is required' };
    }

    if (nameFromField?.trim().toLowerCase() === partnerName?.trim().toLowerCase()) {
      return {
        valid: false,
        errorMessage: 'Your First Name and Your Partner First Name can`t be the same!',
      };
    }

    if (nameFromOtherField?.trim().toLowerCase() === nameFromField?.trim().toLowerCase()) {
      return {
        valid: false,
        errorMessage: 'First Name and Last Name can`t be the same!',
      };
    }

    return this.profanity(nameFromField);
  }

  public static middleName(middleName: string): ValidationModel {
    if (/[^A-Z- ]/gi.test(middleName)) {
      return {
        valid: false,
        errorMessage: 'Name cannot contain numbers or symbols',
      };
    }

    return this.profanity(middleName);
  }

  public static ukAndNationalPhoneNumber(phoneNumber: string): ValidationModel {
    if (phoneNumber && ukMobilePhoneNumberOnlyRegex.test(phoneNumber)) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errorMessage: 'Please enter a valid telephone number',
    };
  }

  public static nationalInsuranceNumber(niNumber: string): ValidationModel {
    if (niNumber && !nationalInsuranceNumberRegex.test(niNumber)) {
      return {
        valid: false,
        errorMessage: 'Please enter a valid National Insurance number',
      };
    }

    return {
      valid: true,
    };
  }

  public static payeReferenceNumber(payeRef: string): ValidationModel {
    if (payeRef && payeReferenceNumberRegex.test(payeRef)) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      errorMessage: 'A PAYE employer reference number has the format 123/AB456',
    };
  }

  public static employerName(employerName: string): ValidationModel {
    const trimmedEmployerName = employerName?.replace(/\s/g, '');

    const employerRegExp: RegExp = new RegExp(
      employerExclusionList.reduce(
        (accum, prof: string, idx: number) =>
          `${accum}${idx !== 0 ? '|' : ''}${prof}${employerExclusionList.length - 1 === idx ? ')\\s*$' : ''}`,
        '^\\s*(',
      ),
      'i',
    );

    if (trimmedEmployerName?.length <= 1) {
      return {
        valid: false,
        errorMessage: "This doesn't look right!",
      };
    }

    if (/[~`£€¡™¢º#∞§¶•ª–≠«æ…≤≥.,@;?!&$%^*()'"<>/\\+=\-{}[\]]{2,}/gm.test(trimmedEmployerName)) {
      return {
        valid: false,
        errorMessage: "This doesn't look right!",
      };
    }

    if (
      /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu.test(
        trimmedEmployerName,
      )
    ) {
      return {
        valid: false,
        errorMessage: "This doesn't look right!",
      };
    }

    if (/^\d*$/gi.test(trimmedEmployerName)) {
      return {
        valid: false,
        errorMessage: "This doesn't look right!",
      };
    }

    if (employerRegExp.test(employerName)) {
      return {
        valid: false,
        errorMessage: "This doesn't look right!",
      };
    }

    return this.profanity(employerName);
  }

  public static employerDoesNotMatch(employer: string, firstName: string, lastName: string): ValidationModel {
    const trimmedEmployer = employer?.replace(/\s\s+/g, ' ').toLowerCase();

    if (employer && firstName && lastName) {
      if (trimmedEmployer.trim() === `${firstName.trim().toLowerCase()} ${lastName.trim().toLowerCase()}`) {
        return {
          valid: false,
          errorMessage: "This can't be your own name",
        };
      }

      if (trimmedEmployer.trim() === `${lastName.trim().toLowerCase()} ${firstName.trim().toLowerCase()}`) {
        return {
          valid: false,
          errorMessage: "This can't be your own name",
        };
      }
    }

    return {
      valid: true,
    };
  }
}
