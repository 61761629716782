// // Mute TS About roptoSession in Window
declare const window: Window & { roptoSession: any };

interface ServiceOptsModel {
  productId: string;
  clientId: string;
}

interface ApiServiceInitConfig {
  baseUrl: string;
  opts: ServiceOptsModel;
  initHeaders?: HeadersInit;
  serviceName?: string;
}

interface FetchOptionsModel extends RequestInit {
  retryCount?: number;
}


class ApiService {
  private readonly serviceName: string;

  readonly environment: string;

  logger = (...args) => {
    if (this.environment === 'dev') {
      // eslint-disable-next-line no-console
      console.log(`%c[${this.serviceName}] :: `, 'color:DodgerBlue', args.join(''));
    }
  };

  private defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
  };

  productId: string;

  clientId: string;

  private readonly baseOptions: RequestInit;

  private readonly baseUrl: string;

  constructor(args: ApiServiceInitConfig) {
    const { baseUrl, opts, initHeaders, serviceName } = args;
    this.serviceName = serviceName || this.constructor.name;
    this.productId = opts.productId;
    this.clientId = opts.clientId;
    this.baseUrl = baseUrl;
    this.baseOptions = { headers: { ...this.defaultHeaders, ...initHeaders } };
    this.environment = process.env.STAGE !== 'production' ? 'dev' : 'prod';
    this.logger('Environment: ', this.environment);
  }

  /** ----------------- Getter/Setter Methods -------------------------  */

  set Headers(headers: HeadersInit) {
    this.baseOptions.headers = headers;
  }

  get Headers(): HeadersInit {
    return this.baseOptions.headers;
  }

  private async fetchRequest<T>(url: string, opts: FetchOptionsModel,): Promise<T> {
    try {
      return (await window.fetch(url, opts)).json();
    } catch (err) {
      opts.retryCount -= 1;
      
      if (opts.retryCount > 0) {
        console.log(err)
        return this.fetchRequest(url, opts)
      } else {
        throw Error(err);
      }
    }
  }

  /** ----------------- Public Methods -------------------------  */
  public get sessionValues() {
    return {
      ...window.roptoSession.getSession(),
      productId: this.productId,
      clientId: this.clientId,
    };
  }

  addHeaders(headers: HeadersInit) {
    this.Headers = { ...this.Headers, ...headers };
  }

  async get<T>(path: string, opts: FetchOptionsModel = this.baseOptions): Promise<T> {
    return this.fetchRequest<T>(`${this.baseUrl}/${path}`, { ...opts, method: 'GET' });
  }

  async post<T>(path: string, body: any, opts: FetchOptionsModel = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.baseUrl}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'POST',
    });
  }

  async put<T>(path: string, body: any, opts: FetchOptionsModel = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.baseUrl}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'PUT',
    });
  }

  async patch<T>(path: string, body: any, opts: FetchOptionsModel = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.baseUrl}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'PATCH',
    });
  }
}

export { ApiService };
