import { StoragePersistence } from './interfaces';

export function getStorageKey({ key, persistence }: { key: string; persistence: StoragePersistence }): object {
  try {
    if (persistence === 'session') {
      return JSON.parse(window.sessionStorage.getItem(key)) || {};
    }
    if (persistence === 'local') {
      return JSON.parse(window.localStorage.getItem(key)) || {};
    }
    return {};
  } catch (e) {
    return {};
  }
}

export function setStorageKey({ key, value, persistence }: { key: string; value: object; persistence: StoragePersistence }): void {
  try {
    if (persistence === 'session') {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
    if (persistence === 'local') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}

export function clearStorageKey({ key, persistence }: { key: string; persistence: StoragePersistence }): void {
  try {
    if (persistence === 'session') {
      window.sessionStorage.setItem(key, JSON.stringify({}));
    }
    if (persistence === 'local') {
      window.localStorage.setItem(key, JSON.stringify({}));
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}
