import { ApiService } from '@src/service';
import { DefendantModel, DefendantsReqBodyModel, GroupModel, LeadModel } from '@model';
import { generateUUID } from '@src/lib';
import { lpServicesService } from '@src/config';
import { VictaProductEnum } from '@enum';

interface LdsInitConfig {
  baseUrl: string;
  opts: { productId: string; clientId: string };
  initHeaders?: HeadersInit;
}

export class LdsService extends ApiService {
  resumeGuid = generateUUID();

  setResumeGuid = (existingGuid: string) => {
    this.resumeGuid = existingGuid;
  };

  constructor(args: LdsInitConfig) {
    const { baseUrl, opts, initHeaders } = args;
    super({ baseUrl, opts, initHeaders, serviceName: 'LDS-Service' });
  }

  async submitLeadData(values: Partial<LeadModel>, resumeGuid?: string): Promise<{ leadId: string }> {
    return this.post(`${this.environment}/lead`, {
      lead: {
        ...this.sessionValues,
        ...values,
        resumeGuid: resumeGuid || this.resumeGuid,
        toe: lpServicesService.toe,
        instructionFor: VictaProductEnum.UNAFFORDABLE_LENDING,
      },
    });
  }

  async updateLeadData(values: Partial<LeadModel>): Promise<{ leadId: string }> {
    return this.patch(`${this.environment}/lead/${this.resumeGuid}`, values);
  }

  async updatePreClaim(args: { ldsLeadId: string; pcpEligible: boolean }): Promise<void> {
    return this.patch(`${this.environment}/claim`, args);
  }

  async defendants(reqBody?: DefendantsReqBodyModel): Promise<{ defendants: DefendantModel[]; groups: GroupModel[] }> {
    return this.post(`${this.environment}/defendants`, reqBody, { retryCount: 3 });
  }

  async validateAndFetchPartialLead(verificationBody: {
    resumeKey: string;
    attemptedDobIso: string;
  }): Promise<{ valid: boolean; partialLead: Partial<LeadModel>; createdAt: string }> {
    return this.post(`${this.environment}/verify-user`, verificationBody);
  }

  async uploadViaPreSignedUrl(file: File, uploadProgressEl?: HTMLProgressElement): Promise<{ fileName: string; file: File }> {
    const presignedUrlPath = `${this.environment}/get-presigned-put-request`;

    const { uploadURL, Key } = await this.get<{ uploadURL: string; Key: string }>(presignedUrlPath, {
      headers: { 'Content-Type': file.type },
    });

    if (uploadProgressEl) {
      uploadProgressEl.value = 0;
    }

    return new Promise((resolve, reject) => {
      try {
        const xhr: XMLHttpRequest = new XMLHttpRequest();

        xhr.upload.onprogress = (event: ProgressEvent) =>
          event.lengthComputable && uploadProgressEl ? (uploadProgressEl.value = (event.loaded / event.total) * 100) : null;

        xhr.onerror = reject;

        xhr.onloadend = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            try {
              resolve({ fileName: Key, file });
            } catch (e) {
              reject(e);
            }
          }
        };

        xhr.open('PUT', uploadURL, true);
        xhr.send(file);
      } catch (e) {
        this.logger(e);
        reject(e);
      }
    });
  }

  async uploadProofOfId(inputItem: { ldsLeadId: string; files: string[] }): Promise<{ message: 'ok' }> {
    return this.post(`${this.environment}/upload-proof-of-id`, inputItem);
  }
}
