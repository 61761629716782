import { LdsService, LpServicesService, MdsService } from '@service';
import { VictaProductEnum } from '@enum';

export const ldsService: LdsService = new LdsService({
  baseUrl: process.env.LDS_URL || 'https://5z2x72ih01.execute-api.eu-west-1.amazonaws.com',
  opts: { productId: '366', clientId: '94' },
});

export const mdsService: MdsService = new MdsService({
  baseUrl: process.env.MDS_URL || 'https://v7njlb8x7i.execute-api.eu-west-2.amazonaws.com',
  opts: { productId: '366', clientId: '1' },
  storageKey: 'mdsLeadId',
  goal: 'TCGL: IL Prospect',
  resumeKeyKey: 'resumekeyTcglUlLp12',
});

export const lpServicesService: LpServicesService = new LpServicesService({
  baseUrl: process.env.LP_SERVICES_URL || 'https://1spvcfs52c.execute-api.eu-west-1.amazonaws.com',
  opts: { product: VictaProductEnum.UNAFFORDABLE_LENDING },
});
