exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-black-horse-sets-aside-tsx": () => import("./../../../src/pages/black-horse-sets-aside.tsx" /* webpackChunkName: "component---src-pages-black-horse-sets-aside-tsx" */),
  "component---src-pages-car-finance-tsx": () => import("./../../../src/pages/car-finance.tsx" /* webpackChunkName: "component---src-pages-car-finance-tsx" */),
  "component---src-pages-close-brothers-cancels-dividends-tsx": () => import("./../../../src/pages/close-brothers-cancels-dividends.tsx" /* webpackChunkName: "component---src-pages-close-brothers-cancels-dividends-tsx" */),
  "component---src-pages-company-information-tsx": () => import("./../../../src/pages/company-information.tsx" /* webpackChunkName: "component---src-pages-company-information-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-oops-tsx": () => import("./../../../src/pages/oops.tsx" /* webpackChunkName: "component---src-pages-oops-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-undisclosed-commission-on-car-finance-tsx": () => import("./../../../src/pages/undisclosed-commission-on-car-finance.tsx" /* webpackChunkName: "component---src-pages-undisclosed-commission-on-car-finance-tsx" */),
  "component---src-pages-we-will-be-in-touch-tsx": () => import("./../../../src/pages/we-will-be-in-touch.tsx" /* webpackChunkName: "component---src-pages-we-will-be-in-touch-tsx" */)
}

